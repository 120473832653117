function leadingZero(i) {
  var num;
  i < 10 ? (num = "0" + i) : (num = i);
  return num;
}

function nowTime() {
  //获取年月日
  var time = new Date();
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var day = time.getDate();
  //获取时分秒
  var h = time.getHours();
  var m = time.getMinutes();
  var s = time.getSeconds();
  var ampm = h >= 12 ? "下午" : "上午";
  //检查是否小于10
  h = leadingZero(h);
  m = leadingZero(m);
  s = leadingZero(s);

  return year + "-" + month + "-" + day + "&nbsp;" + h + ":" + m + ":" + s;
}

function detectFaqHash() {
  let hash = location.hash.split("#")[1];

  // set 'about 关于我们' as default if hash does not exist
  if (hash === "" || hash === undefined) {
    hash = "about";
  }

  $(`.faq-menu .menu-item[data-section="${hash}"]`).addClass("active").siblings(".menu-item").removeClass("active");
  $(`.faq-content .content-item[data-section="${hash}"]`)
    .removeClass("hide")
    .siblings(".content-item")
    .addClass("hide");
}

/** ************** Banner ************** */
function renderBanner() {
  if ($(".banner .swiper-container").length !== 0) {
    const bannerList = window.banner;
    const indexContainer = $("#index-banner.swiper-wrapper");
    const isLoggedIn = $(".before-login.hide").length !== 0;

    bannerList &&
      bannerList.length !== 0 &&
      bannerList.forEach(function (item) {
        const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

        if (imageUrl) {
          /**
           * Redirect Group Option
           * 0 - Promo
           * 1 - Join Us
           * 2 - Lottery
           * 3 - Product
           * 4 - Others inner page (Loyalty, Customer Service)
           * 5 - 自定义
           */

          const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

          banner.click(() =>
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            })
          );

          /**
           * index append 首页type banner, promo append 优惠活动type banner
           * 轮播图类型 bannerType
           * 0 - 首页
           * 1 - 优惠活动
           * 2 - 其他
           */
          if (bannerType === 0) {
            indexContainer.append(banner);
          }
        }
      });

    if (bannerList && bannerList.length) {
      var swiper = new Swiper(".banner .swiper-container", {
        autoHeight: true, //enable auto height
        autoplay: true,
        loop: true,
        pagination: {
          el: ".banner .swiper-pagination",
        },
      });
    }
  }
}

/** ************** Promo ************** */
$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      const promoWrapper = $(".promo-wrapper");
      const promohash = location.hash !== "" ? location.hash.split("#")[1] : "";

      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="promo-box" data-type="${value.type}"></div>`);
        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
              <div class="sub-content-item-header" data-header="${promoId}">
                  ${
                    value.imagePath
                      ? `
                  <img src="${value.imagePath}" />`
                      : `<h3>${value.title}</h3>`
                  }
              </div>
              <div class="sub-content-item-content" ${
                promohash.length > 0 && promohash === promoId ? `style="display:block"` : ""
              }>
                ${value.content}
                ${
                  requestToJoin
                    ? `
                         <div
                           class="sub-content-item-footer"
                           style="display: flex; align-items: center; justify-content: center; height: 50px;font-size: 14px"
                         >
                           ${
                             firstDepositPromoJoinMsg
                               ? `
                                 <div class="sub-content-item-footer--info" style="color:#555;">
                                   ${firstDepositPromoJoinMsg}
                                 </div>
                               `
                               : `
                                 <button
                                   class="sub-content-item-footer--register-promo"
                                   data-id="${promoId}"
                                   style="background-image: linear-gradient(#fefcfe, #fdf563, #f7a927, #ffd14e); cursor:pointer; color:#000; outline:0;height:35px; width:120px; border-radius:20px; border:0;"
                                 >
                                   申请参与
                                 </button>
                               `
                           }
                         </div>
                       `
                    : ``
                }
              </div>
            `)
        );
        promoWrapper.append(promoItem);
      });

      $(".promo-box .sub-content-item-footer--register-promo").click(function (e) {
        e.preventDefault();
        var promoId = $(this).data("id");
        registerPromo(promoId);
      });

      $(".promo-box .sub-content-item-header").click(function (e) {
        e.preventDefault();
        $(this).siblings(".sub-content-item-content").slideToggle();
      });

      if (promohash.length > 0 && $("promo").length > 0) {
        const scrollTop = $(`.sub-content-item-header[data-header='${promohash}']`).offset().top - 50;
        $("html, body").animate({ scrollTop: scrollTop }, 1000);
      }
    }
  }
});

/** ************** Document Ready ************** */
$(document).ready(function () {
  if (Pace) {
    Pace.on("done", renderBanner);
  } else {
    $(window).load(renderBanner);
  }

  /** Clock */
  setInterval(function () {
    $("#nowtime").html(nowTime());
  }, 1000);

  /** Progress Bar */
  $(".progress-bar").each(function () {
    const width = $(this).data("progress");
    $(this).animate({ width: width }, 1000);
  });

  /** 设为首页 */
  $("#addBookmark").click(function () {
    var f = navigator.userAgent.toLowerCase().indexOf("mac") != -1 ? "Command/Cmd" : "CTRL";
    if (document.all) {
      window.external.addFavorite(window.location.href, document.title);
    } else {
      if (window.sidebar) {
        window.sidebar.addPanel(document.title, window.location.href, "");
      } else {
        alert("该版本浏览器无法完成此操作。\n您可以尝试通过快捷键" + f + "+D加入到收藏夹~");
      }
    }
    return false;
  });

  /** Close sidebar */
  $("#btnCloseSidebar").on("click", function () {
    $(".right-sidebar").slideUp(500, function () {
      $(this).remove();
    });
  });

  /** Detect FAQ hash */
  if ($(".faq").length > 0) {
    detectFaqHash();
  }

  $("a").on("click", function () {
    const dataSection = $(this).data("section");
    if (dataSection) {
      location.hash = $(this).data("section");
      detectFaqHash();
    }
  });

  /** Change section in FAQ */
  $(".faq-menu .menu-item").on("click", function () {
    location.hash = $(this).data("section");
    detectFaqHash();
  });

  /** Change section in Aff */
  $(".aff-menu .menu-item").on("click", function () {
    const affSection = $(this).data("aff");
    $(`.aff-menu .menu-item[data-aff="${affSection}"]`).addClass("active").siblings(".menu-item").removeClass("active");
    $(`.aff-content-wrapper .aff-content[data-aff="${affSection}"]`)
      .removeClass("hide")
      .siblings(".aff-content")
      .addClass("hide");
  });
});
